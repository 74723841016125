<template>
  <div class="form">
    <div class="logo">
      <logo />
    </div>
    <div class="title is-3-5 mb-1">Регистрация в системе</div>

    <tnd-input
      class="form__input mt-2"
      placeholder="Ваше имя"
      inputType="login"
      :maxLength="30"
      v-model="formData.username.value"
      :error="formData.username.error"
    >
      <template #before-input>
        <user-icon />
      </template>
    </tnd-input>
    <tnd-input
      class="form__input"
      placeholder="Ваш E-Mail"
      type="email"
      v-model="formData.email.value"
      :error="formData.email.error"
    >
      <template #before-input>
        <mail-icon />
      </template>
    </tnd-input>
    <tnd-input
      class="form__input"
      placeholder="Ваш пароль"
      type="password"
      v-model="formData.password.value"
      :error="formData.password.error"
    >
      <template #before-input>
        <locker-icon />
      </template>
    </tnd-input>
    <button
      @click.prevent="register"
      class="button is-normal main-button purple"
    >
      <span class="icon is-small">
        <user-add-icon />
      </span>
      <span>Регистрация в системе</span>
    </button>
    <a href="/" class="common-link">Перейти на главную</a>
  </div>
</template>

<script>
import LockerIcon from "../icons/LockerIcon.vue";
import Logo from "../icons/Logo.vue";
import MailIcon from "../icons/MailIcon.vue";
import UserAddIcon from "../icons/UserAddIcon.vue";
import UserIcon from "../icons/UserIcon.vue";
import TndInput from "./TndInput.vue";

import authService from "../../services/auth.service";

export default {
  components: { Logo, TndInput, UserIcon, LockerIcon, MailIcon, UserAddIcon },
  data: () => ({
    formData: {
      username: { value: "", error: "" },
      email: { value: "", error: "" },
      password: { value: "", error: "" },
      password_repeat: { value: "", error: "" },
    },
  }),
  methods: {
    clearErrors() {
      Object.entries(this.formData).map(([fieldName, fieldData]) => {
        this.formData[fieldName].error = "";
      });
    },
    fillErrors(errorsData) {
      if (errorsData)
        Object.entries(errorsData).map(([fieldName, errors]) => {
          this.formData[fieldName].error = errors[0];
        });
    },
    async register() {
      this.clearErrors();
      this.formData.password_repeat.value = this.formData.password.value;

      const data = {};
      Object.entries(this.formData).map(([fieldName, fieldData]) => {
        data[fieldName] = fieldData.value;
      });

      const { success, errors } =  await authService.register(data);

      if (success) {
        this.$buefy.toast.open({
          message: "Регистрация пройдена успешно",
          type: "is-success",
          duration: 3000,
        });

        this.$router.push({ name: "login", query: { register: "true"} });
        return;
      }
      if(errors)
        this.fillErrors(errors);
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  min-width: 50%;
  display: flex;
  flex-direction: column;

  .title {
    margin-top: 75px;
  }

  &__input:first {
    margin-top: 28px;
  }

  &__input + &__input {
    margin-top: 6px;
  }

  .button {
    margin-top: 2em;
  }

  .common-link {
    margin-top: 77px;
  }
}
</style>